import { Injectable } from '@angular/core';
import { HttpBaseService } from './http-base.service';

@Injectable({
  providedIn: 'root'
})

export class DynamicRoleCriteriaService {

  constructor(private http: HttpBaseService) { }

  getDynamicRoleCriteria(offset?: any, limit?: any) {

    offset = offset ? '?offset=' + offset : '?offset=0';
    limit = limit ? '&limit=' + limit : '&limit=0';

    return this.http.get('dynamicCriteria/global', {offset, limit});
  }

  getFirmDynamicRoleCriteria(firmId: string, offset?: any, limit?: any) {

    offset = offset ? '?offset=' + offset : '?offset=0';
    limit = limit ? '&limit=' + limit : '&limit=0';

    return this.http.get('dynamicCriteria/firm/'+firmId, {offset, limit});
  }

  getSingleDynamicRoleCriteria(id: string) {
    return this.http.get('dynamicCriteria/' + id);
  }

  createDynamicRoleCriteria(criteria: any) {
    return this.http.post('dynamicCriteria', criteria);
  }

  updateDynamicRoleCriteria(id: string, criteria: any) {
    return this.http.put('dynamicCriteria/' + id, criteria);
  }

  archiveDynamicRoleCriteria(id: string) {
    return this.http.put('dynamicCriteria/archive/' + id);
  }

  unarchiveDynamicRoleCriteria(id: string) {
    return this.http.put('dynamicCriteria/unarchive/' + id);
  }

  deleteDynamicRoleCriteria(id: string) {
    return this.http.delete('dynamicCriteria/' + id);
  }

  getDynamicCredentialGlobalListForDropdown() {
    return this.http.get('dynamicCriteria/globalListDropdown');
  }

  saveCriteriaReviewer(criterias: any) {
    return this.http.post('dynamicCriteria/reviewerCriteria', criterias);
  }

  getReviewerCriteria(id: string) {
    return this.http.get('dynamicCriteria/reviewerCriteria/'+id);
  }  
}
