import { Injectable } from '@angular/core';
import { HttpBaseService } from './http-base.service';
import { lastValueFrom } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class FirmService {

  constructor(private http: HttpBaseService) { }

  getFirms(name: string, offset?: number, limit?: number) {
    return this.http.get('firms', {offset, limit, name});
  }

  getAllFirms() {
    return this.http.get('firms');
  }

  getFirm(id: string) {
    return this.http.get('firms/' + id);
  }

  createFirm(firm: any) {
    return this.http.post('firms', firm);
  }

  updateFirm(firm: any, firmId: string) {
    return this.http.put('firms/' + firmId, firm);
  }

  deleteFirm(id: string) {
    return this.http.delete('firms/' + id);
  }

  deleteFirmDocument(firmId: string, documentId: string) {
    return this.http.delete('firms/' + firmId + '/documents/' + documentId);
  }

  getFirmProjectOwners(firmId: string): Promise<any> {
    return lastValueFrom(this.http.get('firms/' + firmId + "/managers/PROJECT_OWNER"));
  }

  getListOfProjectManagers(firmId: string) {
    return this.http.get('firms/' + firmId + "/managers/PROJECT_OWNER/listOfProjectManagers");
  }

  getListOfFirmUsers(firmId: string, offset?: number, limit?: number, role?: string) {
    return this.http.get('firms/' + firmId + "/users", {offset, limit, role});
  }

  resendConfirmationEmailToFirmManager(firmId: string, firmManagerId: string) {
    return this.http.post('firms/' + firmId + '/managers/' + firmManagerId);
  }

  createFirmUser(firmId: string, user: any) {
    return this.http.post('firms/' + firmId + '/managers', user);
  }

  updateFirmUser(user: any) {
    return this.http.put('users/' + user._id, user);
  }

  createFirmReviewLocation(firmId: string, payload: any): Promise<any>{
    return lastValueFrom(this.http.post('firms/' + firmId + '/reviewLocations', payload));
  }

  getFirmLocationById(firmId: string): Promise<any>{
    return lastValueFrom(this.http.get('firms/' + firmId + '/reviewLocations'));
  }

  updateUserStatus(id:string, status: boolean) {
    return this.http.put('users/' + id + '/status', { status: status });
  };

  getFirmFavourites(firmId: string): Promise<any>{
    return lastValueFrom(this.http.get(`firms/${firmId}/favorites`));
  }
}
